@charset "utf-8";
@import "settings";
@import "assets/vendor/zurb/foundation/scss/foundation"; // @import "assets/components/motion-ui/motion-ui";
@import "flaticon";
@import "slick";
@import "slick-lightbox";
// Or selectively include components
@include foundation-global-styles; //@include foundation-grid;
@include foundation-flex-grid;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms; // @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar; //@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes; //@include foundation-float-classes;
@include foundation-flex-classes;
// @include foundation-prototype-classes;
// @include motion-ui-transitions;
// @include motion-ui-animations;
// FOR IE 10
@-ms-viewport {
   width: device-width;
}
// FONTS
//////////////////////////////////////////////////////////////////////////
@import url('https://fonts.googleapis.com/css?family=Sorts+Mill+Goudy|Open+Sans:300,400&display=swap');
// MIXINS
//////////////////////////////////////////////////////////////////////////
@function f-header-size($breakpoint, $size) {
   @return rem-calc(map-get(map-get(map-get($header-styles, $breakpoint), $size), "font-size") * 1px);
}
@mixin header-size($type) {
   font-size: rem-calc(map-get(map-get(map-get($header-styles, "small"), $type), "font-size") * 1px);
   @include breakpoint(medium) {
      font-size: rem-calc(map-get(map-get(map-get($header-styles, "medium"), $type), "font-size") * 1px);
   }
}
@mixin stak {
   // defaults;
   padding: 20px 0;
   p {
      font-size: 1em;
      &.lead {
         font-size: 100%;
         line-height: 1.6;
         font-family: $font-family-serif;
      }
   }
   .lead {
      p {
         font-family: $font-family-serif;
      }
   }
   @include breakpoint(medium) {
      // enhancements;
      padding: 40px 0;
   }
   @include breakpoint(large) {
      // enhancements;
      padding: 60px 0;
      p {
         font-size: 1em;
         &.lead {
            font-size: f-header-size(medium, h4);
            line-height: 1.6;
         }
      }
      .lead {
         p {
            font-family: $font-family-serif;
            font-size: f-header-size(medium, h4);
         }
      }
   }
}
@mixin hero-slide {
   // defaults;
   height: 300px;
   background-repeat: no-repeat;
   background-size: cover !important;
   z-index: 0;
   overflow: hidden;
   background-position: center center;
   backface-visibility: hidden;
   align-items: center;
   display: flex;
   @include breakpoint(medium) {
      // enhancements;
      height: 400px;
   }
   @include breakpoint(large) {
      // enhancements;
      height: 600px;
   }
   @include breakpoint(xlarge) {
      // enhancements;
      height: 700px;
   }
}
@mixin no-bullet {
   list-style-type: none;
   margin: 0;
}
@mixin stak-pic {
   @include stak;
   display: table;
   width: 100%;
   min-height: 300px;
   .row {
      display: table-row;
      width: 100%;
   }
   .columns {
      display: table-cell;
      float: none;
      vertical-align: middle;
      text-align: center;
   }
   .column:last-child:not(:first-child),
   .columns:last-child:not(:first-child) {
      float: none;
   }
   .vertical-center {
      vertical-align: middle;
   }
   h2 {
      //color: $white;
      line-height: initial;
      span {
         background-color: rgba($body-font-color, 0.8);
         padding: 0 0.25em;
         margin: 0;
         box-decoration-break: clone;
         -webkit-box-decoration-break: clone;
      }
      strong {
         color: $black;
         display: inline-block;
      }
   }
   h3,
   li,
   p,
   strong {
      //color: $white;
   }
   @include breakpoint(large) {
      background-attachment: fixed;
   }
}
@mixin fixed {
   position: fixed;
   top: 0;
   width: 100%;
   z-index: 99;
   left: 0;
   &.expanded:not(.top-bar) {
      height: auto;
      max-height: 100%;
      overflow-y: auto;
      width: 100%;
   }
   &.expanded:not(.top-bar) .title-area {
      position: fixed;
      width: 100%;
      z-index: 99;
   }
   &.expanded:not(.top-bar) .top-bar-section {
      margin-top: 5rem;
      z-index: 98;
   }
}
@mixin radius {
   border-radius: 3px;
}
.foo {
   @include grid-row;
   .bar {
      @include grid-column(12);
   }
}
@media screen and #{breakpoint(medium)} {
   .foo {
      @include grid-row;
      .bar {
         @include grid-column(4);
      }
   }
}
// DEFAULTS (* see _settings.scss for FOUNDATION OVERRIDES)
//////////////////////////////////////////////////////////////////////////
$logo-small: 120px;
$logo-medium: 140px;
$logo-large: 200px;
address {
   font-style: normal;
   margin-bottom: 2em;
   strong {
      color: $black;
   }
}
h3 {
   color: $olive;
}
.button {
   font-weight: bold;
}
.button.hollow {
   border-width: 2px;
}
.nf-form-content{
   input[type=button].ninja-forms-field {
      @include button;
      font-weight: bold;
   }
   label {
      line-height: 1em!important;
      color: $medium-gray;
      font-family: $font-family-serif;
   }
}


.nf-field-label label {
   font-weight: 400;
   margin: 0;
   padding: 0;
}
.columns {
   position: relative;
}
.fixed {
   position: fixed;
   width: 100%;
   z-index: 1;
}
$off-canvas-width: 60%;
.row.expanded {
   max-width: none;
   > .columns {
      padding: 0;
   }
}
:focus {
   outline: none;
}
a.has-tip {
   border-bottom: none;
   color: $primary-color;
   cursor: pointer;
   font-weight: normal;
}
.slick-arrow {
   z-index: 1;
}
.go-button {
   @include button;
   @include header-size(h5);
   margin-top: 1em;
   color: $white !important;
   overflow: hidden;
   width: 250px;
   cursor: pointer;
   white-space: nowrap;
   transition-timing-function: linear;
   transition: all 0.2s ease;
   margin: 0 auto 1em;
   position: relative;
   &:before {
      content: '';
      background: darken($primary-color, 10%) url("../images/site/arrowhead.png") center center no-repeat;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      transition-timing-function: linear;
      transform: translateZ(0) scale(1, 1.1) translate(-101%, 0);
      transition: all 0.2s ease-in-out 0s;
   }
   &:hover:before {
      transform: translateZ(0) scale(1, 1.1) translate(0%, 0);
   }
}
ul.link-list {
   list-style: none;
   margin: 0 auto;
   li:after {
      content: "\203A \20";
      padding-left: 0.25em;
   }
   li:hover:after {
      color: $orange;
   }
   a {
      display: inline-block;
      &:after {
         content: '';
         display: block;
         height: 3px;
         width: 0;
         margin: 0.25em 0 0;
         background: transparent;
         transition: width 0.25s ease, background-color 0.25s ease;
      }
      &:hover:after {
         width: 100%;
         background: $orange;
      }
   }
}
ul.row {
   list-style: none;
   li {
      margin: 0.5em 0;
      line-height: 1.25em;
   }
}
.stak-pic {
   @include stak-pic;
}
// GLOBALS (CUSTOM GLOBALS)
//////////////////////////////////////////////////////////////////////////
.skip-link.screen-reader-text {
   display: none;
}
.sticky-container {
   transition: all 0.25s;
   z-index: 999; // for dropdown pre-scroll
}
header.main-nav {
   background-color: rgba($white, 0.95);
   transition: all 0.25s;
   position: relative;
   .logo {
      width: $logo-small;
      margin: 0.5em 0;
      transition: all 0.25s;
   }
   @include breakpoint(medium) {
      .logo {
         width: $logo-medium;
      }
   }
   @include breakpoint(large) {
      // enhancements;
      .logo {
         width: $logo-large;
      }
   }
   @include breakpoint(xlarge) {
      // enhancements;
      // width: 100%;
      // position: fixed;
      // z-index: 2;
      // top: 0;
   }
   // GLOBAL-MENU ///////////////////////////////////////////////
   ul.global-menu {
      display: inline-block;
      vertical-align: baseline;
      align-self: flex-end;
      width: 100%;
      align-items: flex-end;
      text-align: right;
      > li {
         position: relative;
         text-align: left;
      }
      > li > a {
         font-size: 0.78em;
         padding: 0 1.25em;
         color: $dark-gray;
         line-height: 3em;
         text-transform: uppercase;
         font-weight: 600;
         transition: all 0.5s;
         position: relative;
         text-align: left;
         &:last-child {
            padding-right: 0;
         }
         &:hover {
            color: $secondary-color;
         }
         &:after {
            content: '';
            display: block;
            left: 0;
            height: 3px;
            width: 0;
            background: $primary-color;
            transition: width 0.5s ease, background-color 0.25s ease;
         }
         &.hover:after,
         &:hover:after {
            width: 100%;
            background: $primary-color;
         }
         &.sel {
            color: $secondary-color;
            &:after {
               width: 100%;
               background: $secondary-color;
            }
         }
      }
      @include breakpoint(large) {
         > li > a {
            font-size: 1em;
            padding: 0 1.5em;
         }
      }
      @include breakpoint(xlarge) {
         // enhancements;
         > li > a {
            font-size: 1em;
            padding: 0 2em;
         }
      }
   }

   .dropdown-pane {
      font-size: 0.9em;
      text-align: left;
      &.mega-menu {
         width: 100%!important;
         border: none;
         background-color: transparent;
         padding: 0;
         &.is-open {
            left: 0 !important;
            display: block;
         }
         .content {
            background-color: $white;
            padding: 1.25em;
            border: 1px solid $light-gray;
            a {
               display: inline-block;
               line-height: 1.25em;
               &:hover {
                  color: $secondary-color;
               }
            }
            .description {
               border-top: 1px solid $light-gray;
               padding-top: 0.5em;
               color: $gray;
               p {
                  line-height: 1.25em;
               }
            }
            .nav-title {
               @include header-size(h5);
               font-weight: 700;
               text-transform: uppercase;
               letter-spacing: 0.05em;
               a {
                  color: $gray;
               }
            }
            .menu .button,
            .menu a {
               line-height: 1;
               text-decoration: none;
               display: block;
               padding: 0.7rem 1rem 0.7rem 0;
               font-weight: bold;
            }
            .blocks {
               li {
                  display: inline-block!important;
               }
               a {
                  font-weight: normal;
                  display: inline-block!important;
               }
               .menu-ico {
                  max-width: 30px;
                  display: inline;
                  padding-right: 6px;
                  line-height: 1em;
                  margin: 0;
               }
            }
         }
         img {
            width: 100%;
            margin: 0.75em 0;
            display: block;
         }
      }
      .vertical.menu {
         a {
            &:hover {
               text-decoration: underline;
               color: scale-color($anchor-color, $lightness: -40%);
            }
         }
      }
   }
}
.is-stuck {
   header.main-nav {

      background-color: rgba($white, 0.95);
      height: auto;
      @include breakpoint(xlarge) {
         // enhancements;
         .logo {
            width: $logo-medium;
         }
      }
   }

}
// ancillary
.ancillary {
   text-align: right;
   display: block;
   background-color: $light-gray;
   ul.ancillary-menu {
      margin: 0 !important;
      font-size: 0.8em;
      padding: 0.5em 0;
      display: inline-block;
      list-style-type: none;
      text-transform: uppercase;
      li {
         list-style-type: none;
         padding: 0 0 0 10px;
         a {
            color: $dark-gray;
            padding: 0.5rem;
            &:hover {
               color: $black;
            }
         }
         form {
            display: inline;
         }
      }
   }
   .phone {
      font-weight: bold;
   }
   .contact {
      @include button;
      @include button-hollow;
      font-size: 1em;
      border: lighten($secondary-color, 20%) 1px solid;
      color: $secondary-color;
      margin-bottom: 0;
      transition: all 0.25s;
      margin: 0;
      a {
         color: $secondary-color;
      }
      &:hover {
         border: $secondary-color 1px solid;
      }
   }
   // .x-collapse {
   //    .columns,
   //    .postfix,
   //    a,
   //    button,
   //    input {
   //       margin: 0 !important;
   //    }
   // }
   // input:focus {
   //    outline: none;
   // }
   // .search {
   //    background-color: transparent;
   //    border-color: transparent;
   //    border: none;
   //    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0);
   //    width: 30px;
   //    height:30px;
   //    background: url("../images/search.png") no-repeat scroll 4px center transparent;
   //    float: right;
   //    color: $gray;
   //    padding: 4px;
   //    margin-right: 10px;
   //    text-indent: 30px;
   //    font-size: 1em;
   //    border-radius: 4px;
   //    cursor: pointer;
   //    &::-webkit-input-placeholder {
   //       color: lighten($gray, 20%);
   //    }
   // }
   // .open {
   //    background-color: $white;
   // }
}
// MOBILE NAVIGATION
/////////////////////////////////////
.title-bar {
   text-align: center;
   padding: 0.5em 0 0.25em;
   line-height: 1em;
   .logo {
      width: $logo-small;
      margin: 0 auto;
      padding: 0;
      .fill-black {
         fill: $graphite;
      }
   }
   .menu-ico {
      //margin: -2px 0.3em;
      border: none;
      background: none;
      z-index: 99999;
      transform: scale3d(0.8, 0.8, 0.8);
      transition: 0.3s;
      cursor: pointer;
      user-select: none;
      position: absolute;
      right: 10px;
      top: 1em;
      span {
         display: inline-block;
         width: 2.5rem;
         height: 0.26rem;
         background: $secondary-color;
         border-radius: 0.5em;
         transition: 0.3s;
         position: relative;
      }
      span:before {
         display: inline-block;
         width: 2.5rem;
         height: 0.26rem;
         background: $secondary-color;
         border-radius: 0.5em;
         transition: all 0.3s;
         position: absolute;
         top: 0.75em;
         left: 0;
         content: '';
         transform-origin: 0.13rem center;
      }
      span:after {
         display: inline-block;
         width: 2.5rem;
         height: 0.26rem;
         background: $secondary-color;
         border-radius: 0.5em;
         transition: all 0.3s;
         position: absolute;
         top: -.75em;
         left: 0;
         content: '';
         transform-origin: 0.13rem center;
         //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
      }
      &:hover {
         span {
            background: lighten($secondary-color, 10%);
         }
         span:before {
            background: lighten($secondary-color, 10%);
         }
         span:after {
            background: lighten($secondary-color, 10%);
            //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
         }
      }
   }
}
.menu-ico[aria-expanded="true"] {
   //background-color: $primary-color;
   span {
      background: transparent !important;
   }
   span:before {
      //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
      transform: rotate3d(0, 0, 1, 45deg);
      transform-origin: 50% 50%;
      top: 0;
      background-color: $secondary-color;
   }
   span:after {
      //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
      transform: rotate3d(0, 0, 1, -45deg);
      transform-origin: 50% 50%;
      top: 0;
      background-color: $secondary-color;
   }
}
.right-off-canvas-menu {
   padding-top: 3.75rem;
   //background-color: rgba($jet, .9);
   .menu li a {
      display: block;
      border-bottom-color: darken($green, 10%);
      border-top: 1px solid rgba($white, 0.15);
      color: $white;
      &:hover {
         color: lighten($secondary-color, 50%);
      }
   }
   .menu li:first-of-type {
      a {
         border-top: none;
      }
   }
   .menu li:last-of-type {
      a {
         border-bottom: none;
      }
   }
}
// GLOBAL FOOTER
/////////////////////////////////////
footer.global-footer {
   background-color: lighten($light-gray, 3%);
   padding: 2em 0 0;
   font-size: 0.9em;
   //*:not(.button){color: $gray;}
   text-align: center;
   h5 {
      font-weight: bold;
      margin-top: 1em;
   }
   .logo {
      vertical-align: middle;
      margin: 0 auto;
      max-width: $logo-small;
   }
   ul.display-posts-listing {
      @include no-bullet;
      li {
         padding: 0.2em 0;
         &:first-of-type {
            padding: 0 0 0.5em;
         }
      }
      a {
         text-decoration: underline;
      }
      // a:hover {
      //    color: lighten($primary-color, 50%);
      // }
   }
   .copyright {
      @include grid-column(12);
      margin: 1em auto;
      text-align: center;
      color: $medium-gray;
      p {
         font-size: 0.9em;
         //color: $white;
         vertical-align: middle;
      }
   }
   .social-icons {
      font-size: 1.25em;
      a {
         //margin-left: -.5em;
         margin-right: 0.5em;
      }
      [class^="flaticon-"]:hover:after,
      [class*="flaticon-"]:hover:before {
         color: lighten($primary-color, 10%);
      }
      .fb-icon {
         max-width: 100px;
         .fb-fill{
            fill: $gray;
         }
         &:hover {
            .fb-fill{
               fill: #26559a;
            }
         }

      }

   }
   .telephone {
      display: block;
      margin: 0.5em 0;
   }
   .jobs-btn {
      @include button($expand: true, $background: $white, $style: hollow);
      transition: all, 0.25s;
      border-width: 2px;
      &:hover {
         color: lighten($primary-color, 50%);
         border-color: lighten($primary-color, 50%);
      }
   }
   .bbb-badge {
      margin: 1em auto;
   }
   @include breakpoint(medium) {
      text-align: left;
      h5 {
         margin-top: 0;
      }
      .logo {
         margin: 0;
      }
      ul.display-posts-listing {
         @include no-bullet;
         li {
            border-bottom: 1px solid $white;
            padding: 0.5em 0;
            &:first-of-type {
               padding: 0 0 0.5em;
            }
         }
         a {
            text-decoration: none;
         }
         // a:hover {
         //    text-decoration: underline;
         //    color: lighten($primary-color, 50%);
         // }
      }
   }
}
.icon-callouts {
   img {
      padding-right: 0.5em;
   }
   a:hover {
      h4 {
         color: $primary-color;
      }
   }
}
.stak {
   @include stak;
   //  background-color: $white;
}
.alt-stak {
   @include stak;
   background-color: $light-gray;
}
// HOMEPAGE
//////////////////////////////////////////////////////////////////////////
#home-top-bar {
   width: 100%;
}
.home-hero {
   margin: 0;
   backface-visibility: hidden;
   .hero-caption {
      position: absolute;
      z-index: 1;
      //top: 0;
      right: 0;
      left: 0;
      width: 90%;
      text-align: center;
      //padding: 100px 0 0;
      h1 {
         font-weight: normal;
         margin: 0;
         text-transform: uppercase;
      }
      h2 {
         font-family: $body-font-family;
         font-weight: 300;
         margin: 0;
      }
      h1,
      h3 {
         color: $white;
         line-height: 1em;
         text-shadow: 0px 2px 0px rgba($black, .25), 0px 0px 40px rgba($black, 1);
         letter-spacing: .15em;
      }
      h2 {
         color: $white;
         line-height: normal;
      }
      p {
         color: $white;
         font-size: $paragraph-font-size;
         margin-top: 1em;
         text-shadow: 0px 2px 0px rgba($black, .25), 0px 0px 40px rgba($black, 1);
         font-weight: bolder;
         a {
            color: $primary-color;
         }
      }
      .button {
         @include button;
         margin-top: 2em;
      }
   }
   @include breakpoint(medium) {
      .hero-caption {
         p {
            font-size: $paragraph-font-size + 0.25;
         }
         .button {
            @include button;
            margin-top: 2em;
         }
      }
   }
   @include breakpoint(large) {
      .hero-caption {
         padding: 100px 0 0;
         h1 {
            font-size: f-header-size(medium, h1) + 0.5;
         }
         h2 {
            @include header-size(h2);
         }
      }
   }
   @include breakpoint(xlarge) {
      .hero-caption {
         h1 {
            font-size: f-header-size(medium, h1) + 1.25;
         }
         h2 {
            font-size: f-header-size(medium, h2) + 1;
         }
      }
   }
}
.carousel-hero {
   margin-top: 0;
   margin-left: 0;
   display: none;
   opacity: 0;
   transition: opacity 0.25s ease-in;
   &.slick-initialized {
      opacity: 1;
      display: block;
   }
   @include hero-slide;
   // @include breakpoint(large) {
   //    .slick-next,
   //    .slick-prev {
   //       top: 48%;
   //    }
   // }
   // .slick-slide class is jQuery generated
   .slick-slide {
      @include hero-slide;
      position: relative;
      // @include breakpoint(xlarge) {
      //    clip-path: polygon(0 0, 0 97%, 48% 97%, 50% 100%, 52% 97%, 100% 97%, 100% 0);
      // }
   }
   .slide-caption {
      @include grid-column(12);
      position: absolute;
      bottom: 30px;
      right: 0;
      left: 0;
      font-size: $paragraph-font-size - 0.25;
      text-align: center;
      p {
         background-color: rgba($white, 0.9);
         text-align: center;
         text-transform: uppercase;
         font-weight: 700;
         padding: 1em;
         display: inline;
         border: 1px solid rgba($white, 0.8);
      }
   }
   @include breakpoint(medium) {
      .slide-caption {
         font-size: $paragraph-font-size;
      }
   }
}
.hover-callouts {
   margin: 0 auto;
   > div {
      vertical-align: bottom;
      transition: all 0.5s;
      position: relative;
      background-repeat: no-repeat !important;
      background-position: center center !important;
      background-size: cover !important;
      overflow: hidden;
      background-clip: content-box !important;
      min-height: 300px;
      display: table;
      border: 4px solid $white;
      &:first-of-type {
         border-bottom: 4px solid $white;
      }
      > div {
         border-collapse: collapse;
         display: table-row;
         background-color: rgba($dark-gray, .9);
      }
      .meta {
         height: 96%;
         text-align: center;
         border: 1px solid $transparent;
         padding: 0.5em;
         margin: 0.5em 0 0;
         display: table-cell;
         vertical-align: middle;
         h3,
         h4 {
            font-weight: 400;
            color: $white;
            margin-bottom: 0;
            line-height: 1.25em;
         }
         hr {
            margin: 0.5em 0;
            border-color: $olive;
         }
         p {
            color: $white;
            margin: 1em 0;
         }
      }
      @include breakpoint(medium) {
         overflow: hidden;
         background-size: cover !important;
         transition: all 0.25s;
         min-height: 400px;
         display: block;
         > div {
            background-color: rgba($dark-gray, 0.5);
            transition: all 0.5s;
            height: 100%;
            padding: 0.5em;
            display: block;
         }
         .meta {
            transform: translate(0px, 40%);
            transition: all 0.5s;
            display: block;
            h3 {
               color: $white;
            }
            p {
               color: $white;
               opacity: 0;
               transition-delay: 0;
            }
            hr {
               opacity: 0;
               transition-delay: 0;
               width: 80%;
               margin-right: auto;
               margin-left: auto;
            }
            a {
               opacity: 0;
               transition-delay: 0;
            }
         }
         &:hover {
            > div {
               background-color: rgba($dark-gray, .9);
            }
            .meta {
               transform: translate(0px, 30%);
               transition-delay: 0.25s;
               p {
                  opacity: 1;
                  transition: all 1s;
                  transition-delay: 0.25s;
               }
               hr {
                  opacity: 1;
                  transition-delay: 0.25s;
               }
               a {
                  opacity: 1;
                  transition: all 1s;
                  transition-delay: 0.5s;
               }
            }
         }
      }
      &:hover {
         background-size: 120%;
      }
   }
}
.stats {
   margin-top: 2em;
   font-family: $header-font-family;
   font-weight: 300;
   line-height: 1.25em;
   text-align: center;
   .column {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
   }
   strong {
      font-weight: 600;
      display: block;
      font-size: 1.5em;
      line-height: 1.5em;
      color: $primary-color;
   }
   @include breakpoint(large) {
      font-size: 1em;
   }
}
.testimonials {
   text-align: center;
   .row {
      align-items: center;
      justify-content: center;
   }
}
.quote-slider {
   blockquote {
      margin: 30px 0 0;
      border-left: none;
      p {
         margin: 0;
         letter-spacing: 0.1em;
         &:before {
            content: '“';
         }
         &:after {
            content: '”';
         }
      }
      footer {
         margin: 10px 0;
         font-style: italic;
         font-family: $font-family-serif;
      }
      @include breakpoint(medium) {
         p {
            font-size: 120%;
         }
      }
   }
}
.showroom-callout {
   position: relative;
   &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba($white,.5);
      top: 0;
   }
}
.client-logos {
   margin: 20px 0 0;
   .ri-grid {
      margin: 0 auto;
      position: relative;
      height: auto;
   }
   .ri-grid ul {
      list-style: none;
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
   }
   /* Clear floats by Nicolas Gallagher: http://nicolasgallagher.com/micro-clearfix-hack/ */
   .ri-grid ul:after,
   .ri-grid ul:before {
      content: '';
      display: table;
   }
   .ri-grid ul:after {
      clear: both;
   }
   .ri-grid ul {
      zoom: 1;
      /* For IE 6/7 (trigger hasLayout) */
   }
   .ri-grid ul li {
      perspective: 250px;
      //height: 200px!important;
      margin: 0;
      padding: 0;
      float: left;
      position: relative;
      display: block;
      overflow: hidden;
      //border: 1em solid $white;
      @include breakpoint(medium) {
         //border: 1em solid transparent;
      }
   }
   .ri-grid ul li a,
   .ri-grid ul li div {
      display: block;
      outline: none;
      position: absolute;
      z-index: 1002;
      margin: 0 auto;
      padding: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      background-size: 80%;
      background-position: center center;
      background-repeat: no-repeat;
      box-sizing: content-box;
      filter: grayscale(100%) contrast(110%);
      transition: all 0.25s ease-in-out;
      &:hover {
         filter: grayscale(0%) contrast(100%);
      }
      @include breakpoint(medium) {
         background-size: 60%;
      }
   }
   /* Grid wrapper sizes */
   .ri-grid-size-1 {
      width: 55%;
   }
   .ri-grid-size-2 {
      width: 100%;
   }
   .ri-grid-size-3 {
      width: 100%;
      //margin-top: 0;
   }
   .ri-grid-loading:after,
   .ri-grid-loading:before {
      display: none;
   }
   .ri-loading-image {
      display: none;
   }
   .ri-grid-loading .ri-loading-image {
      position: relative;
      width: 30px;
      height: 30px;
      left: 50%;
      margin: 100px 0 0 -15px;
      display: block;
   }
}
// ABOUT
//////////////////////////////////////////////////////////////////////////
.landing {
   h2 {
      display: initial;
   }
   .hero {
      min-height: 300px;
      background-size: 100%;
      background-position: center center;
      //margin-bottom: 2.500em;
      position: relative;
      background-color: $dark-gray;
      .hero-caption {
         color: white;
         text-align: center;
         transform: translate(0%, -50%);
         top: 50%;
         position: absolute;
         left: 0;
         right: 0;
         margin: auto;
         h1,
         h2 {
            color: white;
            text-shadow: 0px 2px 0px rgba($black, .25), 0px 0px 40px rgba($black, 1);
         }
         h2 {
            font-weight: 300;
            font-size: f-header-size(medium, h2) - 1;
         }
         h1 {
            font-size: f-header-size(medium, h1) - 0.5;
            text-transform: uppercase;
            font-weight: 700;
         }
         p {
            font-size: $paragraph-font-size;
            text-shadow: 0px 2px 0px rgba($black, .25), 0px 0px 40px rgba($black, 1);
            a {
               color: white;
               font-weight: 700;
            }
         }
      }
      @include breakpoint(medium) {
         min-height: 350px;
         background-size: cover !important;
         background-position: center center;
      }
      @include breakpoint(large) {
         min-height: 400px;
         background-size: cover !important;
         .hero-caption {
            h1 {
               font-size: f-header-size(medium, h1) + 0.5;
            }
            h2 {
               @include header-size(h2);
            }
         }
      }
      @include breakpoint(xlarge) {
         min-height: 550px;
         .hero-caption {
            padding-top: 4em;
            // h1 {
            //    font-size: f-header-size(medium, h1) + 1.25;
            // }
            // h2 {
            //    font-size: f-header-size(medium, h2) + 1;
            // }
         }
      }
   }
   .stak:nth-child(even) {
      background-color: $light-gray;
   }
}
.photo-bgd-callout {
   background-position: center center!important;
   background-size: cover;
   .content {
      margin: 2em auto;
      padding: 1em;
      background-color: rgba($white, .9);
      text-align: center;
      color: $medium-gray;
   }
   @include breakpoint(medium) {
      background-attachment: fixed!important;
      .content {
         margin: 4em auto;
         padding: 1em 2em;
         background-color: rgba($white, .9);
      }
   }
}
.cards-stak {
   .card-description {
      margin-bottom: 1em;
      color: $medium-gray;
   }
}
// VIDEO
.flex-video {
   iframe {
      border: 0;
   }
}
.video-backbground {
   background-color: $light-gray;
   padding: 15px;
   margin: 0 15px !important;
}

.video-player {
   // background-color: #fff;
   padding-bottom: 5px;
   .poster {
      position: relative;
      img {
         width: 100%;
      }
   }
   .play-button {
      position: absolute;
      width: 80px;
      height: 80px;
      margin: auto;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      transition: opacity 0.5s ease;
      &:hover {
         opacity: 0.5;
      }
   }
}

// COMMERCIAL
h3,
h4 {
   &.underline {
      position: relative;
      &:after {
         content: "";
         position: absolute;
         bottom: 0;
         left: 0;
         right: 0;
         width: 100%;
         height: 4px;
         background-color: $gray;
      }
   }
   line-height: 1.15em;
}
.align-middle,
.collapse,
.fill-image {
   .content {
      padding: 2em;
   }
   @include breakpoint(xlarge) {
      .content {
         padding: 4em;
      }
   }
 .bgd-image {
      box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25);
   }

}
.fill-image {
   .columns {
      min-height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      @include breakpoint(medium) {
         background-size:  cover;
      }
   }
}
.alternating-staks {
   .stak {
      &:nth-child(odd) {
         background-color: $light-gray;
      }
   }
   img.thumbnail {
      margin: 0.5em auto;
      width: 90%;
   }
}
.gallery-photo {
   &.slick-slider {
      margin-bottom: 4px !important;
   }
   > div {
      // max-width: 320px;
      overflow: hidden;
      vertical-align: middle;
      margin-bottom: 0;
      img {
         //max-width: 640px;
         width: 100%;
         vertical-align: middle;
         margin: 0 auto;
         transform: scale(1);
         transition: transform 0.25s;
      }
   }
}
.with-captions {
   text-align: center;
   .thumbnail {
      margin-bottom: .25em;
   }
   figcaption {
      font-size: .9em;
      color: $secondary-color;
      margin-bottom: 1em;
   }
   a:hover {
      figcaption {
         color: $primary-color;
      }
   }
}
.gallery-thumb {
   //max-width: 640px;
   max-height: 98px;
   background-color: $white;
   div.slick-slide {
      //min-width: 120px;
      max-height: 90px!important;
      overflow: hidden;
      vertical-align: middle;
      text-align: center;
      margin: 0 2px;
      .slick-list {
         max-height: 90px;
      }
      img {
         max-width: 120px;
         min-height: 90px;
         vertical-align: middle;
         padding: 0 4px 0 0;
         margin: 0 auto;
      }
      @include breakpoint(large) {
         img {
            max-width: 150px;
            min-height: 120px;
         }
      }
   }
   .slick-next,
   .slick-prev {
      top: 0;
      background-color: rgba($white, .9);
      z-index: 1;
      height: 100%;
      &:before {
         color: $black;
      }
   }
}

// BLOG
/////////////////////////////////

// Article

.more-articles {
   padding-top: 2em;
   .button {
      border-radius: $button-radius;
   }
}
.article-cards, .tease-post {
   .card {
      transition: all 0.5s;
      display: block;
      background-color: $light-gray;
      overflow: hidden;
      //margin: .9em;
      p {
         font-family: $font-family-serif;
         font-size: 1em;
         margin: 1.5em auto 1.75em;
      }
      .date {
         color: $medium-gray;
         font-family: $font-family-serif;
         font-size: .8em;
      }

      .article-thumb {
         width: 100%;
         display: block;
         position: relative;
      }
      .article-categories {
         margin-bottom: 1em;
         a {
            text-transform: uppercase;
            font-size: .8em;
            color: $secondary-color;
            font-weight: bold;
            &:hover {
               color: lighten($primary-color, 8%);
            }
            &:before {
               content:", ";
            }
         }
         a:first-of-type {
            &:before {
               content:"";
            }
         }
      }

      &:hover {
         img{
            width: 110%;
         }
      }
   }
   @include breakpoint(medium) {
      .card {
         transition: all 0.25s;
         display: block;
         margin: .9em;
         .card-section {
            transition: all 0.5s;
            padding: 1.5em 1em;
         }
      }
      padding-bottom: 2em;
   }
}
.pagination-block {
   text-align: center;
   border-top: 2px solid $light-gray;
   padding-top: 2em;
   font-family: $font-family-serif;
   .disabled {
      color: lighten($gray, 20%);
   }
   .prev {
      float: left;
   }
   .next {
      float: right;
   }
}
.breadcrumb-container {
   margin: 1em 0;
}
.breadcrumbs {
   margin-top: 2em;
   vertical-align:   middle;
   font-size: 0.85em;
   color: $medium-gray;;
   a {
      color: $secondary-color;
      &:hover {
         text-decoration: underline;
      }
   }
   span {
      //font-size: 1em;
   }
   .breadcrumb_last {
      color: $medium-gray;
      cursor: not-allowed;
   }
}
.recent-articles-list {
   margin-bottom: 1em;
   .article-title {
      margin-left: 1em;
      h6 {
         margin-bottom: 0;
      }
      a:hover h6{
         color: lighten($primary-color, 20%);
      }
   }
}
.category-list {
   a {
      padding: 0.7rem 1rem .7rem 0;
   }
   .article-count {
      display: inline;
      margin-right:  1rem;
   }
}
// ARCHIVE
.archive-menu {
   .is-accordion-submenu-parent {
      font-family: $font-family-serif;
      font-size: 2em;
   }
   .is-accordion-submenu-item {
      font-family: $body-font-family;
      font-size: .5em;
   }
}


.article {
   .page-content {
      @include stak;
   }
   // h2, h3, h4 {
   //    color: $gray;
   //    font-weight: normal;
   //    font-family: $font-family-serif;
   //    b {
   //       font-weight: normal;
   //    }
   // }
   p {
      a {
         color: lighten($primary-color, 20%);
         &:hover {
            color: $primary-color;
            text-decoration: underline;
         }
      }
   }
   .cat-title-link {
      a {
         padding: 0 .25em;
         &:after{
            content: ","
         }
         &:last-of-type{
            &:after {
               content: "";
            }
         }
      }
   }
   .featured-image {
      margin-bottom: 2em;
   }
   .article-pagenation {
      margin-top: -1em;
      margin-bottom: 1em;

      a {
         h4 {
            color: $gray;
            //text-decoration: underline;
            font-size: .9em;
            margin-bottom: 0;
         }
         span {
            font-size: .8em;
            line-height: 1em;
            display: block;
            color: lighten($primary-color, 20%);
         }
         &:hover {
            h4, span {
               color: $secondary-color!important;
            }
         }
      }
      @include breakpoint(medium) {
         justify-content: space-between;
         margin-top: -3em;
         margin-bottom: 2em;
         .next-post {
            text-align: right;
         }
      }
   }

   .hero {
      min-height: 100px;
      background-size: 100%;
      background-position: center center;
      position: relative;
      border-bottom: 1px solid $light-gray;
      .hero-caption {
         text-align: center;
         transform: translate(0%, -50%);
         top: 50%;
         position: absolute;
         left: 0;
         right: 0;
         margin: auto;
         h1,
         h2 {
            color: $body-font-color;
         }
         h2 {
            font-weight: 300;
            font-size: f-header-size(medium, h2) - 1;
         }
         h1 {
            font-size: f-header-size(medium, h1) - 0.5;
            font-weight: 400;
         }
         p {
            font-size: $paragraph-font-size;
            a {
               font-weight: 700;
            }
         }
      }
      @include breakpoint(medium) {
         min-height: 200px;
         background-size: cover !important;
      }
      @include breakpoint(large) {
         .hero-caption {
            h1 {
               font-size: f-header-size(medium, h1) + 0.5;
            }
            h2 {
               @include header-size(h2);
            }
         }
      }
   }
}
// .comment-box {
//    margin-top: 2em;
//    padding-top: 2em;
//    border-top: 4px solid $light-gray;
// }
// .comment-form {
//    input, textarea {
//       border: none;
//       background-color: $light-gray;
//       box-shadow: none;
//       color: $gray;
//       &::placeholder {
//          color: lighten($gray, 20%);
//       }
//       &:focus::placeholder{
//          color:transparent;
//       }
//    }
//    input {
//       margin-bottom: 1em;
//    }
//    label {
//       display: none;
//    }
//    textarea {
//       min-height: 148px;
//    }
//    sup {
//       display: block;
//       font-family: $font-family-serif;
//       margin: 1em auto;
//       font-style: italic;
//       color: $gray;
//    }
//    h4 {
//       display: block;
//       font-style: normal;
//       text-transform: uppercase;
//       font-size: f-header-size(small, h6)*.8;
//       color: $primary-color;
//       font-weight: bold;
//       margin-bottom: 1em;
//    }
// }
// LEADERSHIP
.subpage {
   h2 {
      display: initial;
   }
   .hero {
      min-height: 300px;
      background-size: 100%;
      background-position: center center;
      //margin-bottom: 2.500em;
      position: relative;
      background-color: $dark-gray;
      .hero-caption {
         color: white;
         text-align: center;
         transform: translate(0%, -50%);
         top: 50%;
         position: absolute;
         left: 0;
         right: 0;
         margin: auto;
         h1,
         h2 {
            color: white;
            text-shadow: 0px 2px 0px rgba($black, .25), 0px 0px 40px rgba($black, 1);
            //text-shadow: -4px 3px 0 #3a50d9, -14px 7px 0 #0a0e27;
            font-weight: bold;
         }
         h2 {
            font-weight: 300;
            font-size: f-header-size(medium, h2) - 1;
         }
         h1 {
            font-size: f-header-size(medium, h1) - 0.5;
            text-transform: uppercase;
            font-weight: 700;
         }
         p {
            font-size: $paragraph-font-size;
            text-shadow: 0px 2px 0px rgba($black, .25), 0px 0px 40px rgba($black, 1);
            font-weight: 700;
            a {
               color: white;
               font-weight: 700;
            }
         }
      }
      @include breakpoint(medium) {
         min-height: 300px;
         background-size: cover !important;
         background-position: center center;
      }
      @include breakpoint(large) {
         min-height: 350px;
         background-size: cover !important;
         .hero-caption {
            h1 {
               font-size: f-header-size(medium, h1) + 0.5;
            }
            h2 {
               @include header-size(h2);
            }
         }
      }
      @include breakpoint(xlarge) {
         min-height: 400px;
         .hero-caption {
            // h1 {
            //    font-size: f-header-size(medium, h1) + 1.25;
            // }
            // h2 {
            //    font-size: f-header-size(medium, h2) + 1;
            // }
         }
      }
   }
}



// ANIMATIONS
//////////////////////////////////////////////////////////////////////////
.animate {
   opacity: 0;
}
.slideUp {
   animation-name: slideUp;
   animation-duration: 1s;
   animation-timing-function: ease-in;
   animation-fill-mode: forwards;
}
@keyframes slideUp {
   0% {
      transform: translateY(20%);
      opacity: 0;
   }
   25% {
      transform: translateY(-2%);
   }
   75% {
      transform: translateY(0%);
   }
   100% {
      opacity: 1;
   }
}
.fadein {
   opacity: 0;
}
.fadeout {
   opacity: 1;
}
.fadein-animation {
   animation-name: fadein-animation;
   animation-duration: 1s;
   animation-timing-function: ease-in;
   animation-fill-mode: forwards;
}
@keyframes fadein-animation {
   0% {
      transform: translateY(20%);
      opacity: 0;
   }
   25% {
      transform: translateY(0%);
   }
   75% {
      transform: translateY(0%);
   }
   100% {
      opacity: 1;
   }
}
.fadeout-animation {
   animation-name: fadeout-animation;
   animation-duration: 1s;
   animation-timing-function: ease-out;
   animation-fill-mode: forwards;
}
@keyframes fadeout-animation {
   0% {
      transform: translateY(0%);
      opacity: 1;
   }
   25% {
      transform: translateY(0%);
   }
   75% {
      opacity: 0;
      transform: translateY(50%);
   }
   100% {
      opacity: 0;
      transform: translateY(50%);
   }
}
